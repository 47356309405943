.custommargin{
    margin-right: 1rem;
    margin-left: 1rem;
}

@media screen and (max-width: 768px) {
    .custommargin{
        margin-right: 0rem;
        margin-left: 0rem;
    }
    
}