.image-container {
    border-top-left-radius: 25%;
    border-bottom-right-radius: 25%;

    overflow: hidden; 
}

.card-container{
    border-top-left-radius: 25% !important;
    
}
