.arcclass{
    border-radius: 17px;;
}

.background-imagesssssss {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px; 
    border-radius: 13px;
  }
  