.neddclass{
    font-size: 14px;
}
.btncolorclass , .btncolorclass:hover{
    background-color: rgb(204, 28, 30) !important;
}

.btncolorclass , .secondbtn{
    font-size: 14px !important;
    padding: 10px !important;
}
 .secondbtn:hover , .secondbtn:focus{
    background-color:white!important;
    color: gray !important;

}
.neddclasswithcheck{
    font-size: 12px;
}
