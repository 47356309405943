.shadowclass{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.pclass{
    font-size: 12px;
    margin-left: 10px;
    color: gray;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}