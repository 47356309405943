.bgclass{
    background: #FFE7E7 !important;
    color: #CC1C1E !important;
    border: 0 !important;
}
.articleclass{
    display: flex !important;
}
.recArcProImage{
    max-height: 150px;
}

@media only screen and (max-width: 768px) {
.articleclass{
    display: block !important;
}
.recArcProImage{
    max-height: 150px;
}
}