.commentclass{
    color: #6C6C6C;
    font-weight: bold;
}
.colclass{
    width: 88% !important;
}
.btnclasss{
    width: 17% !important;
}
@media screen and (max-width: 768px) {
    .colclass{
        width: 100% !important;
    }
    .btnclasss{
        width: 100% !important;
}
}