.classContainer {
    display: flex;
}

.latstclass {
    margin-left: 3rem;
    margin-top: 3rem;
    margin-bottom: .25rem;
}

.banerclass {
    margin-right: 1.5rem;
    margin-left: 1rem;
    padding: 0;
}

.mainclass {
    margin-left: 1rem;
}

.recbanclass {
    margin: 0;
    padding: 0
}

.recsideclass {
    margin-left: 1.5rem;
}

.load-more-btn:hover {
    transform: scale(1.05); 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
  }

.load-more-btn {
    transition: all 0.3s ease-in-out;
}

.load-more-btn.loading {
    opacity: 0.7;
    cursor: not-allowed;
}

.banerclass {
    transition: all 0.5s ease-in-out;
}

.mainclass {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width:768px) {
    .secondclass {
        display: none;
    }

    .latstclass {
        margin-left: 0;
        margin-top: 1rem;
    }

    .banerclass {
        margin: 0;
    }

    .mainclass {
        margin: 0;
    }

    .recsideclass {
        margin-left: 0;
    }
}