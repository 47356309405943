.firstfootclass{
    font-size: 35px;
}
.lisststylw{
    line-height: 37px;
    font-size: 15px;
}
.cominfo{
    font-size: 15px;
    margin-bottom: 22px;
}
.fsssssssssssss{
    font-size: 15px;
}
.margincol{
    margin-right: 30px;
}
@media screen and (max-width: 768px) {
    .firstfootclass{
        font-size: 22px;
    }   
}