.justified-text {
    text-align: justify;
  }
  .badgeclass{
    border-radius: 50px !important;
    padding: 13px !important;
  }
  .widthClass{
    width: 96% !important;
  }

  .marginclass{
    margin-left: 20px;
  }
  .newwwwwwwwwwww , .newwwwwwwwwwww p{
    text-align: justify !important;
  }
  .newwwwwwwwwwww img {
    max-width: 100%;
    height: auto; 
    display: block; 
    margin: 0 auto; 
  }

  @media only screen and (max-width: 768px) {
    .widthClass{
      width: 100% !important;
    }
    .marginclass{
      margin: 0px !important;
    }
    .paddingclass{
      padding: 0 !important;
    }
    
  }