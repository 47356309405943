.floating-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: linear-gradient(135deg, #798697, #CC1C1E);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1000; /* Ensure it stays above other elements */
  }
  
  .floating-btn:hover {
    transform: scale(1.1);
    background: linear-gradient(135deg, #798697, #CC1C1E);
  }
  
  .floating-btn .icon {
    font-size: 28px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .textplce::placeholder{
    color:#434b53;
   font-size: 14px;
  }