.post-audition-btn {
    color: white;
    padding: 5px 15px;
  }
  
  .post-audition-btn:hover {
    background-color: #CC1C1E;
    color: white;
  }
  
  .navbar-brand span {
    font-weight: bold;
  }
  /* Remove default dropdown arrow */
.dropdown-toggle::after {
    display: none !important;
  }

  .no-border:focus {
    outline: none;
    box-shadow: none;
  }


.navbar .dropdown-item a {
  text-decoration: none; 
  color: #000000;
}

.navbar .dropdown-item a:hover {
  color: #CC1C1E; 
}
.navclass{
  gap: 12px;
}
.btngap{
  gap: 15px;
}
.navclassbrand{
  margin-left: 28px;
}

.navClassHead{
  padding-right: 3rem !important;
    padding-left: 3rem !important;
}
@media (max-width: 768px) {
  .navclassbrand{
    margin-left: 10px;
  }
  .navclass{
    display: flow !important;
  }
  .navClassHead{
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .navclass{
    gap: 7px;
    margin-left: 30px;
  }
}

/* @media only screen and (max-width: 1200px) {
  .navclass{
    gap: 30px;
  }
} */